import React, { useState, useEffect } from 'react';
import { Layout, Button, Space, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	ArrowDownOutlined,
	PrinterFilled,
	ExportOutlined
} from '@ant-design/icons';

import SubHeader from '../Common/SubHeader';
import FilterForAdd from './FilterForAdd';
import QuestionTable from './QuestionTable';
import { getSubjectByCourse } from '../../stateManager/reducers/courseSlice';
import {
	addFilters,
	getQuestion,
	questionExportRequest
} from '../../stateManager/reducers/questionSlice';
import { saveAs } from 'file-saver';
import htmlToDocx from 'html-to-docx';
const { Content } = Layout;

const CourseWiseQuestion = props => {
	const { courseId } = props.match?.params || props;
	const [examView, setExamView] = useState(false);
	const [printAnswer, setPrintAnswer] = useState(false);
	const [withExplanation, setWithExplanation] = useState(true);
	const dispatch = useDispatch();
	const history = useHistory();
	const [showLoadMore, setShowLoadMore] = useState(true);
	const selectedSubjectForFilter = useSelector(
		state => state.questions.selectedSubjectForFilter
	);
	const selectedChapterForFilter = useSelector(
		state => state.questions.selectedChapterForFilter
	);
	const selectedLectureForFilter = useSelector(
		state => state.questions.selectedLectureForFilter
	);

	const selectedCourse = JSON.parse(localStorage.getItem('selectedCourse'));
	const subjectList = useSelector(state => state.courses.subjectList);
	const questionList = useSelector(
		state => state.questions.questionBySubjectCourse
	);

	useEffect(() => {
		if(printAnswer){
			
			window.print()
			setPrintAnswer(false);
		}
	}, [printAnswer]);
	useEffect(() => {
		async function fetchSub() {
			await dispatch(getSubjectByCourse(courseId));
			const historyAction = history.action;
			if (historyAction === 'PUSH') {
				dispatch(
					addFilters({ key: 'selectedSubjectForFilter', value: undefined })
				);
				dispatch(
					addFilters({ key: 'selectedLectureForFilter', value: undefined })
				);
				dispatch(
					addFilters({ key: 'selectedChapterForFilter', value: undefined })
				);
			}
		}
		fetchSub();
	}, [courseId, dispatch, history]);

	useEffect(() => {
		async function fetchData() {
			await dispatch(
				getQuestion({
					courseId,
					lectureId: selectedLectureForFilter,
					chapterId: selectedChapterForFilter,
					subjectId: selectedSubjectForFilter
				})
			);
		}
		fetchData();
	}, [
		selectedSubjectForFilter,
		selectedLectureForFilter,
		selectedChapterForFilter,
		courseId,
		dispatch
	]);

	const handleLoadMore = async () => {
		const isLoadMore = true;
		const lastId =
			questionList && questionList.length > 0
				? questionList[questionList.length - 1]._id
				: undefined;
		const res = await dispatch(
			getQuestion({
				courseId,
				lectureId: selectedLectureForFilter,
				chapterId: selectedChapterForFilter,
				subjectId: selectedSubjectForFilter,
				lastId,
				isLoadMore
			})
		);
		if (res && res?.payload?.data && res?.payload?.data?.length !== 30) {
			setShowLoadMore(false);
		}
	};

	const getQuestionByTitle = async title => {
		await dispatch(getQuestion({ courseId, title }));
	};

	return (
		<React.Fragment>
			<SubHeader
				headText={selectedCourse?.name || 'Not given'}
				backIcon={true}
				backTo='/question-bank'
			/>
			<div
				className='site-layout-background'
				style={{
					padding: 15,
					paddingTop: 0,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<p>Add, view and edit questions</p>
				<div style={{
							marginRight: '20px',
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row',
							rowGap: '10px',
							gap: '20px'

						}} >
						<div>
						With Explanation
						<Switch
						unCheckedChildren='False'
						checkedChildren='True'
						checked={withExplanation}
						style={{
							marginLeft: '10px'
						}}
						onChange={(checked) => {
							setWithExplanation(checked)
						}}

						
					/>
						</div>
						<div>
						Exam View
						<Switch
						unCheckedChildren='False'
						checkedChildren='True'
						checked={examView}
						style={{
							marginLeft: '10px'
						}}
						onChange={(checked) => {
							setExamView(checked)
						}}
						
					/>
						</div>
					</div>

				<Space>
					<Button
						size='large'
						type='primary'
						style={{ margin: '10px' }}
						icon={<ExportOutlined />}
						onClick={() =>
							dispatch(
								questionExportRequest({
									courseId,
									subjectId: selectedSubjectForFilter,
									lectureId: selectedLectureForFilter,
									chapterId: selectedChapterForFilter
								})
							)
						}
					>
						Export Questions
					</Button>
					
					

					<Button
						size='large'
						type='primary'
						icon={<PrinterFilled />}
						style={{ margin: '10px' }}
						onClick={() => {
							
							window.print()
							setPrintAnswer(true)
							
						}}
					>
						Print
					</Button>
					{/* <Button
						size='large'
						type='primary'
						icon={<ExportOutlined />}
						style={{ margin: '10px' }}
						onClick={async () => {
							
							//const tableHtml = document.querySelector('.ant-table').outerHTML;
							const tableHtml = `<!DOCTYPE html>
							<html lang="en">
							<head>
							  <meta charset="UTF-8">
							  <meta name="viewport" content="width=device-width, initial-scale=1.0">
							  <title>Two-column list</title>
							</head>
							<body>
							
							<table>
							  <tr>
								<td>
								<p>1. This is a Test question.</p>
								  <ol style="list-style-type:lower-alpha;">
									<li>Item 1</li>
									<li>Item 2</li>
								  </ol>
								  <ol style="list-style-type:lower-alpha;" data-start="2">
									<li>Item 3</li>
									<li>Item 4</li>
								  </ol>
								</td>
								 <td>
								<p>2. This is a Test question.</p>
								  <ol style="list-style-type:lower-alpha;">
									<li>Item 1</li>
									<li>Item 2</li>
								  </ol>
								  <ol style="list-style-type:lower-alpha;" data-start="2">
									<li>Item 3</li>
									<li>Item 4</li>
								  </ol>
								</td>
							  </tr>
							   <tr>
								<td>
								<p>3. This is a Test question.</p>
								  <ol style="list-style-type:lower-alpha;">
									<li>Item 1</li>
									<li>Item 2</li>
								  </ol>
								  <ol style="list-style-type:lower-alpha;" data-start="2">
									<li>Item 3</li>
									<li>Item 4</li>
								  </ol>
								</td>
								 <td>
								<p>4. This is a Test question.</p>
								  <ol style="list-style-type:lower-alpha;">
									<li>Item 1</li>
									<li>Item 2</li>
								  </ol>
								  <ol style="list-style-type:lower-alpha;" data-start="2">
									<li>Item 3</li>
									<li>Item 4</li>
								  </ol>
								</td>
							  </tr>
							</table>
							
							</body>
							</html>																		
							`;
							console.log(tableHtml);
							const docx = await htmlToDocx(tableHtml);
							saveAs(docx, 'table.docx');
    							//sudo saveAs(converted, 'table.docx');
							
						}}
					>
						Save as Word
					</Button> */}
				</Space>
			</div>

			<FilterForAdd
				subjectList={subjectList}
				courseId={courseId}
				getQuestionByTitle={getQuestionByTitle.bind(this)}
			/>

			<Content className={`exam-details-page teachers-view course-wise-que-table print-teacher`}>
				<QuestionTable data={questionList} examView={examView} withExplanation={withExplanation} printAnswer={printAnswer} />
			</Content>

			{showLoadMore && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignContent: 'center'
					}}
				>
					<Button onClick={() => handleLoadMore()} type='link'>
						Load More <ArrowDownOutlined />
					</Button>
				</div>
			)}
		</React.Fragment>
	);
};

export default CourseWiseQuestion;
