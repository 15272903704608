import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import StudentsList from '../../StudetsManagement/StudentsList';
import CoursesList from '../../Course/CoursesList';
import BranchList from '../../Branch/BranchList';
import SubjectList from '../../Course/SubjectList';
import VideoLectures from '../../VideoLectures/VideoLectures';
import CourseWiseVideoList from '../../VideoLectures/CourseWiseVideoList';
import QuestionBank from '../../QuestionBank/index';
import UserManagement from '../../UserManagement/UserManagement';
import MentorMangement from '../../UserManagement/MentorMangement';
import GroupList from '../../Group/GroupList';
import SingleGroup from '../../Group/SingleGroup';
import CourseWiseQuestion from '../../QuestionBank/CourseWiseQuestion';
import AddQuestion from '../../QuestionBank/AddQuestion';
import SubjectWiseChapterLecture from '../../Course/SubjectWiseChapterLecture';
import LectureList from '../../Course/LectureList';
import ChapterList from '../../Course/ChapterList';
import EditQuestion from '../../QuestionBank/EditQuestion';
import ExamHome from '../../Exam/index';
import CourseWiseExamList from '../../Exam/CourseWiseExamList';
import AddExam from '../../Exam/AddExam';
import QNAManagement from '../../QNAForum/QNAManagement';
import CommentList from '../../QNAForum/QuestionDetails';

import Evaluate from '../../pages/admin/evaluate/Evaluate.page';
import Assessment from '../../pages/admin/evaluate/Assessment.page';
import PublishResult from '../../pages/admin/evaluate/PublishResult.page';
import NotificationPanel from '../../pages/admin/notification/NotificationPanel.page';
import ExamDetails from '../../pages/admin/ExamDetails/ExamDetails.page';
import CombineResults from '../../CombineResults/CombineResults';
import QuestionSolveList from '../../Course/QuestionSolveList';
import StudentScoreboard from '../../pages/admin/StudentScoreboard.page';
import AlternateExam from '../../pages/admin/alternate-exam/AlternateExam.page';
import Dashboard from '../../pages/admin/Dashboard/Dashboard.page';
import { isAdmin } from '../../../constants/constFunction';

const AdminModeratorNavigation = props => {
	return (
		<Switch>
			<Route path='/' exact component={isAdmin() ? Dashboard : StudentsList} />
			{/* {!isAdmin() && <Redirect exact from='/' to='/students-list' />} */}
			<Route path='/students-list' component={StudentsList} />
			<Route
				path='/video-lectures/:courseId'
				exact
				component={CourseWiseVideoList}
			/>
			<Route path='/branch' component={BranchList} />
			<Route path='/courses/:courseId' component={SubjectList} />
			<Route path='/courses' exact component={CoursesList} />
			<Route path='/subject/:subjectId' component={SubjectWiseChapterLecture} />
			<Route exact path='/video-lectures' component={VideoLectures} />
			<Route path='/question-bank/:courseId' component={CourseWiseQuestion} />
			<Route exact path='/question-bank' component={QuestionBank} />
			<Route
				path='/add-question/:courseId/:subjectId/:chapterId/:topic'
				component={AddQuestion}
			/>
			<Route path='/edit-question' component={EditQuestion} />
			<Route path='/user-management' component={UserManagement} />
			<Route path='/mentor-management' component={MentorMangement} />
			<Route path='/qna-management' component={QNAManagement} />
			<Route path='/question-details/:id' component={CommentList} />
			<Route path='/groups/:courseId/:groupId' component={SingleGroup} />
			<Route path='/groups' component={GroupList} />
			<Route path='/lecture/:subjectId' component={LectureList} />
			<Route path='/chapter/:subjectId' component={ChapterList} />
			<Route path='/question-solve/:subjectId' component={QuestionSolveList} />
			<Route path='/exam/:courseId' component={CourseWiseExamList} />
			<Route exact path='/exam' component={ExamHome} />
			<Route path='/add-exam/:courseId/:subjectId' component={AddExam} />

			<Route path='/evaluate/:examId' component={Evaluate} />
			<Route path='/assessment/:studentId' component={Assessment} />
			<Route path='/publish' component={PublishResult} />
			<Route path='/notifications' component={NotificationPanel} />

			<Route exact path='/exam-details/:examId' component={ExamDetails} />
			<Route path='/combine-results' component={CombineResults} />
			<Route path='/scoreboard/:studentId' component={StudentScoreboard} />
			<Route path='/dashboard' component={Dashboard} />
			<Route path='/alternate-exam' component={AlternateExam} />

			{/* <Route component={NotFoundPage} /> */}
		</Switch>
	);
};

export default AdminModeratorNavigation;
