import React from 'react';
import { Layout, Typography, Card, Button, Row, Col, Avatar, Modal, Popover } from 'antd';
import { bucket_url } from '../../constants/constString';
import { DeleteOutlined, LikeOutlined } from '@ant-design/icons';
import { updateStatus, deleteQuestion, deleteComment } from '../../stateManager/reducers/mentorSlice';
import likeFillIcon from '../../assets/images/icons/like-filled.svg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const QUESTION_STATUS = {
    "COMPLETED": "COMPLETED",
    "COMPLETE": "COMPLETE",
    "DECLINE": "DECLINE",
    "DECLINED": "DECLINED",
}

const PostComponent = (props) => {
    const { comment, showStatus, updateQuestionStatus } = props;

    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [fileToView, setFileToView] = React.useState(null);
    const userType = comment.userType;
    const user = JSON.parse(localStorage.getItem('user'));
    const [deletePopoverVisible, setDeletePopoverVisible] = React.useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    React.useEffect(() => {
        console.log('fileToView:', fileToView);
        console.log('isModalVisible:', isModalVisible);

    }, [fileToView]);

    const handleDelete = async () => {
      // Perform delete action here (e.g., call an API to delete an item)
      await dispatch(deleteQuestion(comment._id));
      setDeletePopoverVisible(false); // Close the popover after deleting the item
      //go to the previous page
      history.goBack();
    };
  
    const handleCancel = () => {
      
      setDeletePopoverVisible(false); // Close the popover if canceled
      // Close the popover if canceled
    };
    const content = (
      <div>
        <p>Are you sure you want to delete this question?</p>
        
        <Button type="primary" danger onClick={handleDelete} style={{ marginRight: '8px' }}>
          Yes, Delete
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </div>
    );
  

    const updateStatusHandler = async () => {
        let status;
        switch (comment.status) {
            case QUESTION_STATUS.COMPLETE:
                status = QUESTION_STATUS.COMPLETED;
                break;
            case QUESTION_STATUS.COMPLETED:
                status = QUESTION_STATUS.COMPLETE;
                break;
            case QUESTION_STATUS.DECLINE:
                status = QUESTION_STATUS.DECLINED;
                break;
            case QUESTION_STATUS.DECLINED:
                status = QUESTION_STATUS.COMPLETE;
                break;
            default:
                status = QUESTION_STATUS.COMPLETE;
        }

        try {
            await dispatch(updateStatus({ questionId: comment._id, status }));
            updateQuestionStatus(status);
        } catch (error) {

        }
    }
    const showPreview = (file, type) => {
      setFileToView({
          mediaUrl: bucket_url+ file,
          mediaType: type
        })
      setIsModalVisible(true);
  }
    const renderFilePreview = (file, type) => {
      if (type.startsWith('image/')) {
        return <div className="img-section" onClick={()=>showPreview(file, type)}><img src={bucket_url+ file} alt={file.name} className="image-item" /></div>;
      } else if (type ===  'application/pdf') {
        return (
          <div className='pdf-section'
          style={{
            cursor: 'pointer',
           
          }}
          onClick={() => {
            setFileToView({
              mediaUrl: bucket_url + file,
              mediaType: type,
            });
            setIsModalVisible(true);
          }}
        >
          {/* Instead of directly embedding <object> here, use a wrapper div */}
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <object
              className="pdf"
              data={bucket_url + file}
              width="200"
              height="100"
              style={{
                pointerEvents: 'none', // Disable pointer events for object tag to avoid click interference
              }}
            />
          </div>
        </div>)
      } else if (type.startsWith('audio/')) {
        return <div className="audio-section"> <audio controls src={bucket_url+ file} /></div>;
      }
      return null;
    };
    const getTile = () => {
        if (comment.userType === "Admin") {
          return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end', // This ensures all items in this flex container are vertically centered
                marginBottom: '24px',
                justifyContent: 'flex-end',
            }}>
                <div style={{
                    lineHeight: '10px', // Increased line height for better visual alignment
                    textAlign: 'right'
                }}>

                    <h3 className="user-name"><span style={{color: "#018CE8"}}>{`Teacher, `}</span><span style={{
                    color: "#475569",
                        fontStyle: 'underline',
                    }}>{`${comment.userId ? comment.userId.firstName : 'Guest'} ${comment.userId? comment.userId.lastName:'User'}`}</span></h3>
                    <h6 className="time">{new Date(comment.createdAt).toLocaleString()}</h6>
                </div>
                {/* <Avatar
                    style={{ 
                        backgroundColor: comment.userType === "Admin" ? "red" : "blue",
                    }} className="avatar"
                >
                    {comment.userType === "Admin" ? "A" : "S"}
                </Avatar> */}
            </div>
          );
        } else {
            return ( 
              <div  className="user-content">
                <div className="user-data">
                  {/* <Avatar
                      style={{ 
                      backgroundColor: comment.userType === "Admin" ? "red" : "blue"
                      }}
                      className="avatar"
                  >
                  {comment.userType === "Admin" ? "A" : "S"}
                  </Avatar> */}
                  <div>
                      <h3 className="user-name">{comment?.userId?.name || 'Guest'}</h3>
                      <h6 className="time">{new Date(comment.createdAt).toLocaleString()}</h6>
                  </div>
                </div>
                {comment.status && <div className="question-btn-section">
                   <Button warning disabled={comment.status === QUESTION_STATUS.COMPLETED} onClick={()=>{updateStatusHandler();}} className='mark-as-read-btn'>{comment.status}</Button>
                  <Popover
                    content={content}
                    title="Confirm Delete"
                    trigger="click"
                    open={deletePopoverVisible}>
                      {
                         user.roles.includes('admin') && <Button danger onClick={()=>{
                          setDeletePopoverVisible(true);
                          }} className='question-delete-btn'>
                          Delete
                                  </Button>
                      }
                      
                  </Popover>

                </div>
                    }
               {/* {
                showStatus && <div style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
          <Button danger onClick={()=>{
            updateStatusHandler();
          }}>{comment.status}</Button>
          
          <Popover
          content={content}
          title="Confirm Delete"
          trigger="click"
          open={deletePopoverVisible}>
            <Button type="primary" danger onClick={()=>{
            setDeletePopoverVisible(true);
            }}>
            Delete
            </Button>
            </Popover>

            </div>
               } */}
                
                
            </div>);
        }
    }

    const mediaView = () => {
        return <Modal title="Media" open={isModalVisible} destroyOnClose 
       width={1000}
        footer={null}
        centered
        onCancel={() => {
            setFileToView(null);
            setIsModalVisible(false);
        }
        }
        >
            {
                 fileToView?.mediaType.startsWith('image/') ?
                 <img src={fileToView?.mediaUrl} alt="media" width="100%" />:
                <object class="pdf" data={fileToView?.mediaUrl}  style={{width: '100%', height: '90vh',}}> </object>
                
            }
           
        </Modal>
    }

  return (
    <>
    <Card className={`question-card ${userType}`}>
      <Row>
      <Col xs={{ span: 24 }} lg={{ span: 24}} className={`total-data ${userType}-data`}>

          {getTile()}
          <p style={{ textAlign: comment.userType === "Admin" ? "right" : "left", }} className="question-text">{comment.reply}
          </p>
          {/* {comment.media.length > 0 && <p className="attachment-text">Attachment</p>} */}
          {comment.media.length > 0 && (
              
              <div  className="attachment-section" 
            >
                  <Row gutter={[20,20]}  className='image-parent media-row'> 
                    {comment.media.filter(item=> item.mediaType.startsWith('image/')).map((file, index) => (
                          
                        <Col xs={{ span: 24 }} lg={{ span:8 }} key={index}>{renderFilePreview(file.mediaUrl, file.mediaType)}
                        </Col>
                    ))}
                  </Row>
                  <Row gutter={[20,20]}  className='pdf-parent media-row'> 
                    {comment.media.filter(item=> item.mediaType.startsWith('application/pdf')).map((file, index) => (
                          
                          <Col xs={{ span: 24 }} lg={{ span:8 }} key={index}>{renderFilePreview(file.mediaUrl, file.mediaType)}
                          </Col>
                    ))}
                </Row>
                <Row gutter={[20,20]}  className='audio-parent media-row'> 
                  {comment.media.filter(item=> item.mediaType.startsWith('audio/')).map((file, index) => (
                          
                          <Col xs={{ span: 24 }} lg={{ span:8 }} key={index}>{renderFilePreview(file.mediaUrl, file.mediaType)}
                          </Col>
                  ))}
                </Row>
              </div>
          )}
          {
                comment.userType === "Admin" && (
                    <div  className='upvote-section'>
                        <Button className='like-btn' type="text">
                            
                             <img src={likeFillIcon} alt="like" style={{
                                width: '20px',
                                height: '20px',
                            }}/> 
                        </Button>
                        <span>{comment.upvotes} Upvotes</span>
                    </div>
                )
            }
            {
                comment.userType === "Student" && !showStatus && user.roles.includes('admin') && (
                    <div  className='delete-section'>
                        <Button className='like-btn' type="text" onClick={async ()=>{
                          console.log('comment:', comment);
                            await dispatch(deleteComment(comment._id));
                            
                        }}>
                            <DeleteOutlined />
                        </Button>
                       
                    </div>)
            }
          
      </Col>
      </Row>
    </Card>
    {
      mediaView()
    }
    </>

  );
};

export default PostComponent;
