import React, { useEffect, useState } from 'react'
import { Button, Modal, Select, Space, Tag } from 'antd'
import { assignSubjectToMentor, getMentorOnlyAssignedSubject } from '../../stateManager/reducers/mentorSlice'
import { useDispatch, useSelector } from 'react-redux'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'

const { Option } = Select

const AssignSubjectModal = (props) => {
	// State for dropdowns
	const { isVisible, isEdit, user, updateUser, handleModalOpen } = props
	const [selectedSubjects, setSelectedSubjects]                  = useState([])
	const [selectedCourse, setSelectedCourse]                      = useState(null)
	const assignedSubjects                                         = useSelector(state => state.mentor.assignedSubjects)
	const courses                                                  = useSelector(state => state.mentor.courses)
	const mentorData                                               = useSelector(state => state.mentor.mentors)
	const dispatch                                                 = useDispatch()

	console.log('mentorData', mentorData);
	useEffect(() => {
		async function fetchData() {
			await dispatch(getMentorOnlyAssignedSubject(user._id))
		}

		user && fetchData()

	}, [user])

	console.log('selectedSubject', selectedSubjects)
	useEffect(() => {

		setSelectedSubjects(assignedSubjects)

	}, [assignedSubjects])

	// Handle first dropdown change
	const handleFirstDropdownChange = (value) => {
		const selectedCourse = courses.find((course) => course._id === value)
		setSelectedCourse(selectedCourse)
		console.log('selectedCourse', selectedCourse)
	}

	const submit = async () => {
		console.log('selectedSubjects', selectedSubjects)
		//only subject id
		await dispatch(assignSubjectToMentor({ mentorId: user._id, subject: selectedSubjects.map(item => item._id) }))
		handleModalOpen(false, false)
		setSelectedCourse(null)
		setSelectedSubjects([])
		updateUser(undefined)
	}

	// Handle second dropdown change
	const handleSecondDropdownChange = (value) => {

		const selectedSubject = selectedCourse.subjects.find(
			(subject) => subject._id === value,
		)
		//check if subject already exists

		const isExist = selectedSubjects.find((item) => item._id === selectedSubject._id)
		if (isExist) {
			return
		}
		setSelectedSubjects([
			...selectedSubjects, {
				...selectedSubject,
				courseId: {
					_id : selectedCourse._id,
					name: selectedCourse.name,
				},
			},
		])
		// remove selected subject from dropdown
		setSelectedCourse({
			...selectedCourse,
			subjects: selectedCourse.subjects.filter((subject) => subject._id !== value),
		})
	}

	// Remove item from selected list
	const removeItem = (item) => {
		setSelectedSubjects(selectedSubjects.filter((i) => i._id !== item._id))
		setSelectedCourse({
			...selectedCourse,
			subjects: [
				...selectedCourse.subjects,
				{
					_id : item._id,
					name: item.name,
				},
			],
		})
	}

	return (
		<Modal
			open={isVisible}
			destroyOnClose
			centered
			onCancel={() => {
				updateUser(undefined)
				handleModalOpen(false, false)
				setSelectedCourse(null)
				setSelectedSubjects([])
			}}
			onOk={() => submit()}
			okText={'Submit'}
			cancelButtonProps={{
				style: { display: 'none' },
			}}
			footer={null}
			bodyStyle={{
				background: '#F8FAFC',
				padding   : '68px 120px 60px',
			}}
			width={'60%'}
		>
			<Space size={48} direction={'vertical'} style={{ width: '100%' }}>
				<div style={{
					color     : '#EE473F',
					fontSize  : '32px',
					fontWeight: 600,
				}}>{'Manage Mentor Role'}</div>
				<Space size={24} direction={'vertical'} style={{ width: '100%' }}>
					<Select
						placeholder={'Mentor Name'}
						style={{ width: '100%' }}
						size={'large'}
						value={user?._id}
						onChange={(value) => updateUser(mentorData?.find(mentor => mentor._id === value))}
						disabled={isEdit}
					>
						{
							mentorData.map((mentor) => (
								<Option key={mentor._id} value={mentor._id}>
									
									<div>{mentor.firstName + ' ' + mentor.lastName}, ID:  {mentor.adminId} </div>
									
								</Option>
							))
						}
					</Select>
					<Select
						placeholder={'Select Course'}
						style={{ width: '100%' }}
						size={'large'}
						onChange={handleFirstDropdownChange}
					>
						{
							courses.filter(course => course.session === "2024").map((course) => (
								<Option key={course._id} value={course._id}>
									{course.name}
								</Option>
							))
						}
					</Select>
					<div style={{
						border    : '1px solid #d9d9d9',
						width     : '100%',
						minHeight : '40px',
						background: 'white',
						display   : 'flex',
						alignItems: 'center',
						padding   : '8px 12px',
						flexWrap  : 'wrap',
						gap       : '8px',
					}}>
						{
							selectedCourse?.subjects && selectedCourse.subjects.length > 0 &&
							selectedCourse?.subjects
								.map(subject => (
									<div
										key={subject._id}
										style={{
											background  : '#F1F5F9',
											padding     : '8px 16px',
											fontSize    : '14px',
											border      : '1px solid #CBD5E1',
											borderRadius: '6px',
											color       : '#EE473F',
											cursor      : 'pointer',
										}}
										onClick={() => handleSecondDropdownChange(subject._id)}
									>
										<PlusOutlined style={{ color: '#EE473F', paddingRight: '4px' }} />
										{subject.name}
									</div>
								))
						}
					</div>

					<div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '10px' }}>
						{selectedSubjects?.map((item) => (
							<Tag
								key={item._id}
								closable
								onClose={() => removeItem(item)}
								color={'#EE473F'}
								style={{
									padding     : '8px 16px',
									fontSize    : '14px',
									borderRadius: '6px',
									cursor      : 'pointer',
								}}
								closeIcon={<CloseOutlined style={{ paddingLeft: '6px', fontSize: '14px' }} />}
							>
								{item.name}
							</Tag>
						))}
					</div>
				</Space>
				<div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
					<Button
						danger={true}
						type={'primary'}
						style={{
							background  : '#EE473F',
							width       : '200px',
							height      : '48px',
							borderRadius: 6,
						}}
						onClick={() => submit()}
					>{'Assign Subject'}</Button>
				</div>
			</Space>
		</Modal>
	)
}

export default AssignSubjectModal
