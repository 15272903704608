import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toastr from "toastr";
import { instance } from "../../constants/constString";
import { getCourses } from "./courseSlice";

const initialState = {
  mentorSubjectsList: [],
  assignedSubjects: [],
  mentors: [],
  qnaList: [],
  courses: [],
  comments: [],
  status: "idle",
  error: null,
};

export const getCourseSubjects = createAsyncThunk(
  "course/subject",
  async () => {
    try {
      const res = await instance.get("/course/subject");
      // console.log('mentor subjects', res.data);
      return res.data;
    } catch (error) {
      toastr.error(error.message);
      return error;
    }
  }
);

export const fetchMentors = createAsyncThunk(
  "users/fetchMentors",
  async (searchKey) => {
    try {
      const response = await instance.get(
        `/admin/get-all-mentors?searchKey=${searchKey}`
      );
      return response;
    } catch (err) {
      toastr.error(err.response.data.errors.title);
      return Promise.reject(err);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  "qna/deleteQuestion",
  async (questionId) => {
    try {
      const response = await instance.delete(
        `/qa/delete-question/${questionId}`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const createComment = createAsyncThunk(
  "qna/createComment",
  async (data) => {
    try {
      const response = await instance.post(`/qa/add-comment`, data);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const deleteComment = createAsyncThunk(
  "qna/deleteComment",
  async (commentId) => {
    try {
      const response = await instance.delete(`/qa/delete-comment/${commentId}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getComments = createAsyncThunk(
  "qna/getComments",
  async (questionId) => {
    try {
      const response = await instance.get(`/qa/get-comments/${questionId}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const assignSubjectToMentor = createAsyncThunk(
  "mentor/assign-subject",
  async (data) => {
    try {
      const res = await instance.post("qa/add-mentor-subject", data);
      return res.data;
    } catch (error) {
      toastr.error(error.message);
      return error;
    }
  }
);

export const addComment = createAsyncThunk("qna/addComment", async (data) => {
  try {
    const response = await instance.post(`/qa/add-comment`, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const getMentorAssignedSubject = createAsyncThunk(
  "mentor/assigned-subject",
  async (userId) => {
    try {
      const res = await instance.get(`qa/get-mentor-subjects/${userId}`);
      return res.data;
    } catch (error) {
      toastr.error(error.message);
      return error;
    }
  }
);

export const getMentorOnlyAssignedSubject = createAsyncThunk(
  "mentor/assigned-subject-mentor",
  async (userId) => {
    try {
      const res = await instance.get(`qa/get-only-mentor-subjects/${userId}`);
      return res.data;
    } catch (error) {
      toastr.error(error.message);
      return error;
    }
  }
);

export const getALlCourseWithSubject = createAsyncThunk(
  "qna/getALlCourseWithSubject",
  async () => {
    try {
      const response = await instance.get(`/course/subject`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const createQuestion = createAsyncThunk(
  "qna/createQuestion",
  async (data) => {
    try {
      const response = await instance.post(`/qa/add-question`, data);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getQuestions = createAsyncThunk("qna/getQNAList", async (data) => {
  try {
    const response = await instance.post(`/qa/get-questions`, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const updateStatus = createAsyncThunk(
  "qna/updateStatus",
  async (data) => {
    try {
      const response = await instance.post(`/qa/update-status`, data);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getQuestion = createAsyncThunk("qna/getQuestion", async (id) => {
  try {
    const response = await instance.get(`/qa/get-question/${id}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
});

const formatMentorSubjects = (data) => {
  const courseMap = {};
  data.forEach((subject) => {
    const { courseId, ...subjectData } = subject;
    if (!courseMap[courseId._id]) {
      courseMap[courseId._id] = {
        _id: courseId._id,
        name: courseId.name,
        session: courseId.session,
        subjects: [],
      };
    }
    courseMap[courseId._id].subjects.push({
      _id: subjectData._id,
      name: subjectData.name,
      chapters: subjectData.chapters,
    });
  });

  const coursesWithSubjects = Object.values(courseMap);
  return coursesWithSubjects;
};

export const getQuestionKeyword = async (searchString) => {
  try {
    const response = await instance.post(`/qa/get-question-keyword`, {
      searchString,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const mentorSlice = createSlice({
  name: "mentor",
  initialState,
  reducers: {
    // getMentorSubjectsList: (state) => {
    //   return state.mentorSubjectsList;
    // },
    getCourses: (state) => {
      return state.courses;
    },
  },
  extraReducers: {
    [getCourseSubjects.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.courses = action.payload.data;
    },
    [getCourseSubjects.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCourseSubjects.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getMentorAssignedSubject.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const courseMap = {};

      //state.assignedSubjects = action.payload.data.subjects;
      state.mentorSubjectsList = formatMentorSubjects(
        action.payload.data.subjects
      );
    },
    [getMentorAssignedSubject.pending]: (state, action) => {
      state.status = "loading";
    },
    [getMentorAssignedSubject.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [assignSubjectToMentor.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const mentorId = action.payload.data.mentorId;
      const subjectIds = action.payload.data.subjects;

      const courses = JSON.parse(JSON.stringify(state.courses));
      const subjects = [];
      courses.forEach((course) => {
        course.subjects.forEach((subject) => {
          if (subjectIds.includes(subject._id)) {
            subjects.push({
              _id: subject._id,
              name: subject.name,
            });
          }
        });
      });

      const mentorIndex = state.mentors.findIndex(
        (mentor) => mentor._id === mentorId
      );
      state.mentors[mentorIndex].subjects = subjects;
    },
    [assignSubjectToMentor.pending]: (state, action) => {
      state.status = "loading";
    },
    [assignSubjectToMentor.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getQuestions.pending]: (state, action) => {
      state.status = "loading";
    },
    [getQuestions.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("action.payload?.data", action.payload?.data);
      state.qnaList = action.payload?.data;
    },
    [getQuestions.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [createQuestion.pending]: (state, action) => {
      state.status = "loading";
    },
    [createQuestion.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [createQuestion.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [createComment.pending]: (state, action) => {
      state.status = "loading";
    },
    [createComment.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const admin = JSON.parse(localStorage.getItem("user"));
      console.log("student", admin);
      state.comments = [
        ...state.comments,
        {
          ...action.payload.data,
          userId: {
            _id: admin.id,
            firstName: admin.firstName,
            lastName: admin.lastName,
          },
        },
      ];
    },
    [createComment.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getComments.pending]: (state, action) => {
      state.status = "loading";
    },
    [getComments.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.comments = action.payload.data;
    },
    [getComments.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [createComment.pending]: (state, action) => {
      state.status = "loading";
    },
    [createComment.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("action.payload.data", action.payload.data);
      const user = JSON.parse(localStorage.getItem("user"));
      state.comments = [
        ...state.comments,
        {
          ...action.payload.data,
          userId: {
            _id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
          },
        },
      ];
    },

    [createComment.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [updateStatus.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateStatus.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const question = action.payload.data;
      const index = state.qnaList.findIndex(
        (item) => item._id === question._id
      );
      state.qnaList[index] = question;
    },
    [updateStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteQuestion.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteQuestion.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.qnaList = state.qnaList.filter(
        (item) => item._id !== action.payload.data._id
      );
    },
    [deleteQuestion.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [fetchMentors.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchMentors.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.mentors = action.payload?.data?.data;
    },
    [fetchMentors.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getMentorOnlyAssignedSubject.pending]: (state, action) => {
      state.status = "loading";
    },
    [getMentorOnlyAssignedSubject.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.assignedSubjects = action.payload.data.subjects;
      // state.mentorSubjectsList = formatMentorSubjects(
      //   action.payload.data.subjects
      // );
    },
    [getMentorOnlyAssignedSubject.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteComment.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteComment.fulfilled]: (state, action) => {
      state.status = "succeeded";
      toastr.success("Comment deleted successfully");
      state.comments = state.comments.filter(
        (comment) => comment._id !== action.payload.data._id
      );
    },
    [deleteComment.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const { getMentorSubjectsList } = mentorSlice.actions;
export const status = (state) => state.mentor.status;
export const mentorSubjects = (state) => state.mentor.mentorSubjectsList;
export const assignedSubjects = (state) => state.mentor.assignedSubjects;
export const selectAllMentors = (state) => state.mentor.mentors;
export const courses = (state) => state.mentor.courses;
export const qnaList = (state) => state.mentor.qnaList;
export const comments = (state) => state.mentor.comments;
export default mentorSlice.reducer;
