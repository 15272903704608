import React, { forwardRef, useImperativeHandle } from 'react';

import { Form, Input, Button, Upload, Select, message } from 'antd';

import { Layout } from 'antd';

import  FileUploader  from './FileUploader';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';

import {
	patchFileRequest,
	patchFileRequest2,
	signedUrl as signedUrlRequest
}  from '../../stateManager/reducers/contentSlice';

import { createComment } from '../../stateManager/reducers/mentorSlice';

import { useSelector } from 'react-redux';

const { Option } = Select;
const { TextArea } = Input;
const { Header, Content } = Layout;


const StudentQuestionForm =forwardRef((props, ref) => {
  const {questionId, updateQuestion} = props;
  const [form] = Form.useForm();
  const [files, setFiles] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);



  const dispatch = useDispatch();

  // const getSignedUrl = async (file) => {
  //   try {
  //     const signedUrlRes = await dispatch(signedUrlRequest(file.type));
  //     console.log('Signed URL response:', signedUrlRes);
  //     await uploadFile(file, signedUrlRes.data.signedUrl);
  //   } catch (error) {
  //     console.error('Error getting signed URL:', error);
  //   }
  // };


  const onFinish = async () => {
    
    const media = []
    for (const file of files) {
      const signedUrlRes = await dispatch(signedUrlRequest(file.type));
        try {
        const res =  await dispatch(patchFileRequest2({signedUrl: signedUrlRes?.payload?.data?.signedUrl,file: file}));
        console.log('File upload response:', res);
        media.push({
          mediaType: file.type,
          mediaUrl: signedUrlRes?.payload?.data?.key,
        });
        
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      userId: user.id,
      questionId: questionId,
      userType: 'Admin',
      reply: form.getFieldValue('reply'),
      media: media,
      // tags: form.getFieldValue('tags'),
    };

     await dispatch(createComment(data));
     updateQuestion();
    setIsModalOpen(false);

    message.success('Question submitted successfully!');

    form.resetFields();

  };


  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Please check the form for errors.');
  };

  React.useEffect(() => {
    console.log('Files:', files);
  }, [files]);

  return (
    <div>
      <Button type="outlined" danger onClick={()=>{
        setIsModalOpen(true);
    }} style={{
            width: '100%',
            height: '50px',
            textAlign: 'left', 
          }}
          
          > Reply...
          </Button>
      <Modal
      centered
      open={isModalOpen}
      destroyOnClose={true}        
      footer={null}
      onCancel={() => {
       
        form.resetFields();
        setFiles([]);
        setIsModalOpen(false);
      }
      }
      className="custom-modal"
        closeIcon={<div className="modal-close-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path d="M24 8L8 24M8 8L24 24" stroke="#EE473F" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>}
     >
      <Layout style={{
          height: 'auto',
        }} className="modal-layout">
          <Content className="modal-content">
          <h4 className="modal-title">Reply</h4>
            <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          >

          <Form.Item
            
            name="reply"
            rules={[{ required: true, message: 'Please enter the question details' }, { max: 1000, message: 'Details cannot exceed 500 characters' }]}
          >
            <TextArea rows={4} placeholder="Write your reply" />
          </Form.Item>
          <Form.Item
            name="file"
            valuePropName="fileList"
            >
            <FileUploader files={files} setFiles={setFiles}/>
            </Form.Item>
            
             <Form.Item style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '0',
              marginTop: '48px',
            }}>
            <Button type="primary" danger htmlType="submit"
              className="modal-submit-btn"
              >Submit</Button>
          </Form.Item> 
        </Form>
            </Content>
          </Layout>
      </Modal>
    </div>
      

			

   
  );
});

export default StudentQuestionForm;
