import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import QNAManagement from '../../QNAForum/QNAManagement';
import CommentList from '../../QNAForum/QuestionDetails';

const MentorNavigation = props => {
	return (
		<React.Fragment>
			<Route path='/qna-management' component={QNAManagement} />
            <Route path='/question-details/:id' component={CommentList} />
		</React.Fragment>
	);
};

export default MentorNavigation;