import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import { setContentName } from '../../stateManager/reducers/contentSlice';
import { useDispatch } from 'react-redux';

import { roles } from '../../constants/constString';
import {
	isAdminOrModerator,
	isInstructor,
	isExaminer,
	isExamModarator,
	isAdmin
} from '../../constants/constFunction';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import config from '../../config'

const { Sider } = Layout;

const SiderAdmin = props => {
	const dispatch = useDispatch();
	const user = JSON.parse(localStorage.getItem('user'));

	return (
		<Sider
			breakpoint='lg'
			collapsedWidth='0'
			style={{
				background: '#43425D',
				height: '100vh',
				position: 'fixed',
				zIndex: 9999,
				overflow: props.broken ? 'visible' : 'auto'
			}}
			onBreakpoint={broken => props.toggleBroken(broken)}
			onCollapse={(collapsed, type) => console.log(collapsed, type)}
		>
			<Link to='/' className='logo-wrap'>
				{/* <Logo className='logo' style={{ height: 70 }} /> */}
				<img src={'https://assets.retinalms.com/static-images/retina_logo.svg?updatedAt=1641979291129'} alt="logo" style={{ width: 170, height: 70 }} />
			</Link>
			<Menu
				theme='dark'
				mode='inline'
				defaultSelectedKeys={['17']}
				style={{ background: '#43425D' }}
			>
				{isAdmin() && (
					<Menu.Item key='17'>
						<Link to='/dashboard'>Dashboard</Link>
					</Menu.Item>
				)}
				{(user.roles.indexOf(roles.ADMIN) > -1 ||
					user.roles.indexOf(roles.MODERATOR) > -1) && (
					<Menu.Item key='2'>
						<Link to='/students-list'>Students</Link>
					</Menu.Item>
				)}

				{(isAdminOrModerator() || isExamModarator() || isExaminer()) && (
					<Menu.Item key='3'>
						<Link to='/groups'>Groups</Link>
					</Menu.Item>
				)}
				{isAdminOrModerator() && (
					<Menu.Item key='16'>
						<Link to='/combine-results'>Combine Results</Link>
					</Menu.Item>
				)}
				{isAdminOrModerator() && (
					<Menu.Item key='15'>
						<Link to='/branch'>Branches</Link>
					</Menu.Item>
				)}
				{(user.roles.indexOf(roles.ADMIN) > -1 ||
					user.roles.indexOf(roles.MODERATOR) > -1) && (
					<Menu.Item key='5'>
						<Link to='/courses'>Courses</Link>
					</Menu.Item>
				)}
				{(user.roles.indexOf(roles.ADMIN) > -1 ||
					user.roles.indexOf(roles.MODERATOR) > -1 ||
					user.roles.indexOf(roles.CONTENT_UPLOADER) > -1) && (
					<Menu.Item key='6' onClick={() => dispatch(setContentName('video'))}>
						<Link to='/video-lectures'>Lecture Videos</Link>
					</Menu.Item>
				)}
				{(user.roles.indexOf(roles.ADMIN) > -1 ||
					user.roles.indexOf(roles.MODERATOR) > -1 ||
					user.roles.indexOf(roles.CONTENT_UPLOADER) > -1) && (
					<Menu.Item key='7' onClick={() => dispatch(setContentName('file'))}>
						<Link to='/video-lectures'>Lecture Notes</Link>
					</Menu.Item>
				)}
				{(isAdminOrModerator() || isExamModarator() || isInstructor()) && (
					<Menu.Item key='8'>
						<Link to='/exam'>Exam</Link>
					</Menu.Item>
				)}
				{(user.roles.indexOf(roles.ADMIN) > -1 ||
					user.roles.indexOf(roles.MODERATOR) > -1 ||
					user.roles.indexOf(roles.QUESTION_UPLOADER) > -1) && (
					<Menu.Item key='9'>
						<Link to='/question-bank'>Questions</Link>
					</Menu.Item>
				)}
				{user.roles.indexOf(roles.ADMIN) > -1 && (
					<Menu.Item key='10'>
						<Link to='/user-management'>User Management</Link>
					</Menu.Item>
				)}
				{(user.roles.indexOf(roles.ADMIN) > -1 ||
					user.roles.indexOf(roles.MODERATOR) > -1) && (
					<Menu.Item key='11'>
						<Link to='/notifications'>Notification</Link>
					</Menu.Item>
				)}
				{
					user.roles.indexOf(roles.ADMIN) > -1 && 
					<Menu.Item key='12'>
						<Link to='/alternate-exam'>Alternate Exam</Link>
				</Menu.Item>
				}
				

				{user.roles.indexOf(roles.ADMIN) > -1 && (
					<Menu.Item key='13'>
						<Link to='/mentor-management'>Mentor Management</Link>
					</Menu.Item>
				)}
				{(user.roles.indexOf(roles.ADMIN) > -1 || user.roles.indexOf(roles.MENTOR) > -1) && (
					<Menu.Item key='14'>
						<Link to='/qna-management'>QNA Management</Link>
					</Menu.Item>
				)}
			</Menu>
		</Sider>
	);
};

export default SiderAdmin;
