import React, { useState } from 'react';
import { Table, Tag, Popconfirm, Space, Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import AddUserModal from './AddUserModal';
import { deleteUser } from '../../stateManager/reducers/userManagementSlice';

const UserListTable = props => {
	const dispatch = useDispatch();
	const [isAddModalVisible, setAddModalVisible] = useState(false);
	const [selectedUser, setSelectedUser] = useState(undefined);
	const data = useSelector(state => state.userManagement.adminUsers);

	const toggleModal = () => setAddModalVisible(!isAddModalVisible);

	const columns = [
		{
			title: <strong>Name</strong>,
			key: 'name',
			dataIndex: 'name',
			render: (text, record) => record.firstName + ' ' + record.lastName
		},
		{
			title: <strong>Phone</strong>,
			key: 'phone',
			dataIndex: 'username'
		},
		{
			title: <strong>Email</strong>,
			key: 'email',
			dataIndex: 'email'
		},
		{
			title: <strong>Roles</strong>,
			key: 'roles',
			dataIndex: 'roles',
			render: roles => roles.map(item => <Tag>{item}</Tag>)
		},
		{
			title: <strong>Actions</strong>,
			dataIndex: 'username',
			key: 'edit',
			render: (text, item, index) => (
				<Space>
					<Button
						onClick={() => {
							setSelectedUser(item);
							setAddModalVisible(!isAddModalVisible);
						}}
						type='text'
					>
						<EditOutlined />
					</Button>
					<Popconfirm
						title='Are you sure to delete the user?'
						onConfirm={async () => {
							await dispatch(deleteUser({ userId: text, index }));
						}}
					>
						<Button type='text'>
							<DeleteOutlined />
						</Button>
					</Popconfirm>
				</Space>
			)
		}
	];

	return (
		<React.Fragment>
			<AddUserModal
				isVisible={isAddModalVisible}
				toggleModal={toggleModal.bind(this)}
				isEdit={true}
				user={selectedUser}
			/>
			<Table columns={columns} dataSource={data} />
		</React.Fragment>
	);
};

export default UserListTable;
