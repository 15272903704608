import React, { useEffect, useRef, useState } from 'react';
import { pdfjs, Document, Page } from'react-pdf';
import { Button, Divider, Popover, Radio, Slider, Space } from 'antd';
import { UndoOutlined, RedoOutlined, ZoomInOutlined, ZoomOutOutlined, RotateLeftOutlined, RotateRightOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import './style.css';
import mime from 'mime-types';
import Modal from 'antd/lib/modal/Modal';
import { signedUrl as signedUrlRequest, patchFileRequest } from '../../../../stateManager/reducers/fileSystem';
import { useDispatch } from 'react-redux';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const integerToPointMap  = new Map()
const imageDataArray = [];
export default function PdfDrawing({
  visible,
  setVisible,
  pdfInfo,
  readLocalData
}) {

  // const canvas = useRef();
  const canvasRefs = useRef([]);
  const bkCanvasRefs = useRef([]);
  const initializeCanvasRefs = (numPages) => {
    console.log("initializeCanvasRefs", numPages);
    canvasRefs.current = Array.from({ length: numPages }, () => React.createRef());
    bkCanvasRefs.current = Array.from({ length: numPages }, () => React.createRef());
  };
  const contextRef = useRef(null);
  const [isRendered, setIsRendered] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDrawing, setIsDrawing] = useState(false);
  const [pointList, setPointList] = useState([]);
  const [strokeList, setStrokeList] = useState([]);
  const dispatch = useDispatch();
  const [brushSize, setBrushSize] = useState(5);
  const [color, setColor] = useState('#c90808');
  const [currentStroke, setCurrentStroke] = useState(0);
  const [scale, setScale] = useState(1.75);
  const [selectedTool, setSelectedTool] = useState('draw');
  const [isUploading, setIsUploading] = useState(false);
  const [rotate, setRotate] = useState(0);


  useEffect(()=>{

    numberOfPages > 0 && setIsRendered(Array.from({ length: numberOfPages }, () => false));
  }, [scale, rotate]);
  function onRenderSuccess(e) {
    // console.log("onRenderSuccess", e._pageIndex);
    // console.log("onRenderSuccess isRendered", e)
 
    // console.log("onRenderSuccess isRendered", isRendered)
    
    setIsRendered(prev=>{
      const temp = [...prev];
      console.log("temp", temp);
      temp[e._pageIndex] = true;
      return temp;
    
    }
    );

  }

  // useEffect(() => {
  //   console.log("canvas", canvas);
  //   if (!isRendered || !canvas.current) {
  //     return;
  //   }

  //   console.log("currentPage", currentPage);
  //   var context = canvas.current.getContext('2d');

  //   contextRef.current = context;

  //   if(integerToPointMap.has(currentPage)){
  //       console.log("from Memory", integerToPointMap.get(currentPage));
  //       const arrayOfStrokes = integerToPointMap.get(currentPage);
  //       for(let i = 0; i < arrayOfStrokes.length; i++){
  //           const arrayOfPoints = arrayOfStrokes[i];
  //       contextRef.current.beginPath();
  //       contextRef.current.moveTo(arrayOfPoints[0].offsetX, arrayOfPoints[0].offsetY);
  //       for (let i = 1; i < arrayOfPoints.length; i++) {
  //           contextRef.current.lineTo(arrayOfPoints[i].offsetX, arrayOfPoints[i].offsetY);
  //       }
  //       contextRef.current.stroke();
  //       contextRef.current.closePath();
  //   }   
  //   }

  // }, [isRendered, canvas]);


  const startDrawing = (event, page) => {

    event.preventDefault();
    //event.stopPropagation();
    const canvas = canvasRefs.current[page - 1].current;
    if (!canvas) return;
    setIsDrawing(true);

    
    const { offsetX, offsetY } = event.nativeEvent;
    pointList.push({ offsetX, offsetY });
    // context.globalCompositeOperation = 'destination-out';

    const context = canvas.getContext('2d');
    if(selectedTool === 'draw'){
      context.globalCompositeOperation = 'source-over';
    }
    else if(selectedTool === 'erase'){
      context.globalCompositeOperation = 'destination-out';
    }
    
    context.strokeStyle = color;
    context.linecap = 'round';
    context.lineWidth = brushSize;
    context.beginPath();
    context.moveTo(offsetX, offsetY);

    console.log("startDrawing", page);
    // context.lineTo(offsetX, offsetY);
    // context.stroke();


  };

  const drawOnCanvas = (offsetX, offsetY) => {
  
  }

  const draw = (event, page) => {

    
   
    if (!isDrawing) return;
    const canvas = canvasRefs.current[page - 1].current;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    const { offsetX, offsetY } = event.nativeEvent;
    context.strokeStyle = color;
    context.linecap = 'round';
    context.lineWidth = brushSize;
    context.lineTo(offsetX, offsetY);
    context.stroke();
    pointList.push({ offsetX, offsetY });
    console.log("draw", currentPage);
    
  };

  const stopDrawing = (page) => {
   
    if (!isDrawing) return;
    setIsDrawing(false);
    const canvas = canvasRefs.current[page - 1].current;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    
    context.closePath();
    
    setStrokeList([...strokeList, {
      points: pointList,
      page: page,
      brushSize: brushSize,
      color: color,
      tool: selectedTool
    }]);
    setCurrentStroke(currentStroke + 1);
    setPointList([]);
    console.log("stopDrawing", page);
    
  };

  const addImage = () => {
    const canvas = canvasRefs.current[currentPage - 1].current;
    if (!canvas) return;

    const imgData = canvas.toDataURL('JPEG', 1.0);
    imageDataArray[currentPage - 1] = imgData;
  };

  useEffect(() =>{ 
    //check whether every isRendered is true
    if(isRendered.every((el) => el === true)){
      redrawStroke();
    }
    }, [isRendered]);

    const redrawStroke = () => {
      console.log("handleUndo", currentStroke);

  for(let i = 0; i < numberOfPages; i++){
      const canvas = canvasRefs.current[i].current;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
  }

  console.log("strokeList", strokeList, currentStroke);
  for(let i = 0; i < currentStroke ; i++){
              const { points, page, brushSize, color, tool } = strokeList[i];
              const canvas = canvasRefs.current[page - 1].current;
              const context = canvas.getContext('2d');
          
              if(tool === 'draw'){
                context.globalCompositeOperation = 'source-over';
              }
              else if(tool === 'erase'){
                context.globalCompositeOperation = 'destination-out';
              }
              context.strokeStyle = color;
              context.linecap = 'round';
              context.lineWidth = brushSize;
              context.beginPath();
              context.moveTo(points[0].offsetX, points[0].offsetY);
              for (let i = 1; i < points.length; i++) {
                  context.lineTo(points[i].offsetX, points[i].offsetY);
              }

             
          context.stroke();
          context.closePath();
          
      }  
    }

// useEffect(() => {
//   if (!isRendered || !canvas.current) {
//     return;
//   }
//   addImage();
// }, [isRendered, currentPage]);

const savePDF = async () => {
  //save pdf with images

  setIsUploading(true);
  let orientation = 'p';

  const canvasWidth = bkCanvasRefs.current[0].current.width;
  const canvasHeight = bkCanvasRefs.current[0].current.height;

  if(canvasWidth > canvasHeight){
    orientation = 'l';
  }

  var pdf = new jsPDF(orientation, 'px', 'a4', true);
  
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();

  console.log('number of pages', numberOfPages);
  console.log('pdfWidth', canvasRefs.current[0].current.width, canvasRefs.current[0].current.height);

  for (let i = 0; i < numberOfPages ; i++) {
      //pdf.addImage(imageDataArray[i], 'JPEG', 0, 0);

      const bkCanvas = bkCanvasRefs.current[i].current;
      const canvas = canvasRefs.current[i].current;

    const imgData = canvas.toDataURL('JPEG', 1.0);
    const bkImgData = bkCanvas.toDataURL('JPEG', 1.0);
    pdf.addImage(bkImgData, 'JPEG', 0, 0, pdfWidth, pdfHeight, undefined,'FAST');
      pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight, undefined,'FAST');
      if(i < numberOfPages - 1){
          pdf.addPage();
      }
     
  }
  
    
    const blob = pdf.output('blob')
    const randomNumber = Math.floor(Math.random() * 100000)
    const filename = `${randomNumber}.${mime.extension(blob.type)}`;

    const file = new File([blob], filename, { type: blob.type });

    const res = await dispatch(signedUrlRequest(file.type));
    const { signedUrl, key } = res?.payload?.data;
    
    console.log('File Upload', res?.payload?.data);
    const upload = await dispatch(patchFileRequest({ signedUrl, file }));
    

    if(upload?.payload?.status !== 200){
      console.log('File Upload Error', upload?.payload?.status);
      resetCanvas();
      setIsUploading(false);
      setVisible(false);
      return;
    }

    // const { questionId, key: imgKey } = imageKey
    const questionId = pdfInfo?.questionId
    const imgKey = pdfInfo?.key

    let extra = JSON.parse(localStorage.getItem('extra'));
    let _extra = extra[questionId]

    let indexOfImage = _extra.findIndex(el => el.key === imgKey)
    console.log('indexOfImage', indexOfImage, _extra, imgKey, key);
    if (indexOfImage > -1) {
        _extra[indexOfImage].key = key
    }
    extra[questionId] = _extra

    // _extra[question.questionId] = question?.extra
    localStorage.setItem(
        'extra',
        JSON.stringify(extra)
    );
    readLocalData(extra);
    resetCanvas();
    setIsUploading(false);
    setVisible(false);
  
    //pdf.save("download.pdf");

}

console.log("Extra", JSON.parse(localStorage.getItem('extra')));

const handleUndo = () => {

  //clear all the canvas

  console.log("handleUndo", currentStroke);

  for(let i = 0; i < numberOfPages; i++){
      const canvas = canvasRefs.current[i].current;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
  }

  console.log("strokeList", strokeList, currentStroke);
  for(let i = 0; i < currentStroke - 1; i++){
              const { points, page, brushSize, color, tool } = strokeList[i];
              const canvas = canvasRefs.current[page - 1].current;
              const context = canvas.getContext('2d');
          
              if(tool === 'draw'){
                context.globalCompositeOperation = 'source-over';
              }
              else if(tool === 'erase'){
                context.globalCompositeOperation = 'destination-out';
              }
              context.strokeStyle = color;
              context.linecap = 'round';
              context.lineWidth = brushSize;
              context.beginPath();
              context.moveTo(points[0].offsetX, points[0].offsetY);
              for (let i = 1; i < points.length; i++) {
                  context.lineTo(points[i].offsetX, points[i].offsetY);
              }

             
          context.stroke();
          context.closePath();
          
      }  

      //strokeList.pop();

  currentStroke > 1 && setCurrentStroke(currentStroke - 1);
  
};

const handleRedo = () => {
  console.log("handleUndo", currentStroke);

  for(let i = 0; i < numberOfPages; i++){
      const canvas = canvasRefs.current[i].current;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
  }

  console.log("strokeList", strokeList, currentStroke);
  const strokeListLength = currentStroke < strokeList.length? (currentStroke) : strokeList.length;
  console.log("strokeListLength", strokeListLength);
  for(let i = 0; i < strokeListLength; i++){
              const { points, page, brushSize, color, tool } = strokeList[i];
              const canvas = canvasRefs.current[page - 1].current;
              const context = canvas.getContext('2d');
          
              if(tool === 'draw'){
                context.globalCompositeOperation = 'source-over';
              }
              else if(tool === 'erase'){
                context.globalCompositeOperation = 'destination-out';
              }
              context.strokeStyle = color;
              context.linecap = 'round';
              context.lineWidth = brushSize;
              context.beginPath();
              context.moveTo(points[0].offsetX, points[0].offsetY);
              for (let i = 1; i < points.length; i++) {
                  context.lineTo(points[i].offsetX, points[i].offsetY);
              }

             
          context.stroke();
          context.closePath();
          
      }  

     currentStroke < (strokeList.length) && setCurrentStroke(currentStroke + 1);
};

useEffect(() => {
  console.log("isUploading", isUploading);
}, [isUploading]);

const changeColor = (e) => {
 
  // const context = canvas.current.getContext("2d");
  // context.strokeStyle = e.target.value;
  // context.lineCap = "round";
  // context.lineWidth = 5;
  // contextRef.current = context;
  console.log("color", e.target.value);
};

const changeTool = (e) => {

}
const resetCanvas = (e) => {


  // Cannot read properties of undefined (reading 'current')// solve this issue

  if(!canvasRefs.current){
    return;
  }

  if(numberOfPages === 0){
    return;
  }

  for(let i = 0; i < numberOfPages; i++){
      const canvas = canvasRefs.current[i].current;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
  }
  setStrokeList([]);
  setCurrentStroke(0);
  setPointList([]);
  setIsDrawing(false);
  setBrushSize(5);
  setColor('#c90808');
  setSelectedTool('draw');
  console.log("resetCanvas");


}


  return (
    <Modal
      wrapClassName="evaluate-image-modal-wrap"
      className='evaluate-image-modal'
      title="Evaluate Image"
      open={visible}
      bodyStyle={{height: 'calc(100dvh - 120px)',overflow:'auto' }}
      onCancel={() => {
      setVisible(false);
      resetCanvas();
                
                }
                }
                width={'auto'}
                centered
                onOk={()=>{
                    savePDF();
                    
                }}

            >
              
                <div className=''>
               
                
   <div className='d-flex justify-space-between flex-wrap mb-5' style={{maxWidth:'1253px', position:'sticky',top:'-30px',left:'0px',background:'white', width:'c100%',padding:'15px 0px',zIndex:'100'}}>
   {
                isUploading &&  <div className="demo-container mb-1">
                <div className="progress-bar">
                  <div className="progress-bar-value"></div>
                </div>
                </div>
              }
   <Space >
                
            
                <Space>
                
                    <Radio.Group
                        options={[
                            {
                                label: 'Draw',
                                value: 'draw',
                            },
                            {
                                label: 'Erase',
                                value: 'erase',
                                // disabled: !(currentState > 0)
                            },
                        ]}
                        onChange={(e)=>{
                            
                            setSelectedTool(e.target.value);
                        
                        }}
                        value={selectedTool}
                        defaultValue={selectedTool}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </Space>
                <Space>
                    <Popover
                        className="brush-popover"
                        placement="bottom"
                        trigger="click"
                        // open={open}
                        // onOpenChange={handleOpenChange}
                        content={
                            <div style={{ width: 100 }}>
                                <Slider defaultValue={brushSize} max={10} onChange={setBrushSize} />
                            </div>
                        }
                    >
                        <Button>Brush Size: {brushSize}</Button>
                    </Popover>
                    <input type="color" name="color-pick" id="colorPick" defaultValue='#000000' value={color} onChange={
                        (e) => {
                            setColor(e.target.value);
                        }
                    } />
                </Space>
                <Space>
                    <Button
                        title='Undo'
                        onClick={handleUndo}
                        // disabled={!(currentState > 0)}
                    ><UndoOutlined /></Button>
                    <Button
                        title='Redo'
                        onClick={handleRedo}
                        
                    ><RedoOutlined /></Button>
                    <Button onClick={()=>{
                      
                      setRotate(rotate - 90);
                    
                    }}>
                    <RotateLeftOutlined />
                    </Button>
                    <Button onClick={()=>{
                      
                     
                      setRotate(rotate + 90);
                    }}>
                    <RotateRightOutlined />
                    </Button>
                   
                    <Button title='Reset' onClick={(e) => resetCanvas()}>Reset</Button>
                    {/* <Button onClick={(e) => saveImageToLocal()}><DownloadOutlined /></Button> */}
                </Space>
                <Space>
                    <Button
                        title='-'
                        onClick={()=>{

                          if(scale > 1){
                            setScale(scale - 0.25);
                          }
                        }}
                        // disabled={!(currentState > 0)}
                    ><ZoomOutOutlined /></Button>
                    <Button
                        title='+'
                         onClick={()=>{
                          if(scale < 2.5){
                            setScale(scale + 0.25);
                          }

                         }}
                        // disabled={!(currentState < history.length - 1)}
                    ><ZoomInOutlined /></Button>
                   
                    {/* <Button onClick={(e) => saveImageToLocal()}><DownloadOutlined /></Button> */}
                </Space>
               
                {/* {(currentState > 0) && <Button onClick={undo}>Undo</Button>} */}
            </Space>
   </div>
           
      
       

<div >
  {
    pdfInfo &&
    <Document file={pdfInfo?.link} 
  onLoadSuccess={(pdf) => {
    console.log("numPages", pdf.numPages);
      setNumberOfPages(pdf.numPages);
      initializeCanvasRefs(pdf.numPages);
     
      //setRendered as false with number of pages
      setIsRendered(Array.from({ length: pdf.numPages }, () => false));
     
      }

  }
  >
  {/* <Page
    pageNumber={currentPage}
    canvasRef={canvas}
    onMouseDown={startDrawing}
    onMouseMove={draw}
    onMouseUp={stopDrawing}
    onRenderSuccess={onRenderSuccess}    
    scale={2}
  /> */}

{Array.from({ length: numberOfPages }, (_, index) => (
<div className="position-relative">
    {
     isRendered[index] && <canvas 
        ref={canvasRefs.current[index]}
        onMouseDown={(e) => startDrawing(e, index+1)}
        onMouseMove={(e) => draw(e, index+1)}
        onMouseLeave={(e) =>{
         
          stopDrawing(index+1)
        } }
        onMouseUp={() => stopDrawing(index+1)}
        width={bkCanvasRefs.current[index]?.current?.width}
        height={bkCanvasRefs.current[index]?.current?.height}
        style={{
            position: "relative",
            zIndex: 99
        }}
        
    />
      }
    <div style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  zIndex: 98
              }} >
                 <Page
                pageNumber={index+1}
                rotate={rotate}
                canvasRef={bkCanvasRefs.current[index]}
                onRenderSuccess={onRenderSuccess}    
                scale={scale}
                orientation='l'
                
                />

    </div>
    <Divider/>
   
    
</div>
               
              ))}



</Document>
  }
 

</div>



   </div>
    </Modal>
   
   
  );
}

// ReactDOM.render(<PdfDrawing />, document.getElementById('app'));
