import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
	Button,
	Card,
	Checkbox,
	Col,
	Image,
	Popconfirm,
	Radio,
	Row,
	Space,
	Typography,
	Table
} from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PdfPlaceholder from '../../assets/images/pdf_placeholder.png';
import { shouldUseKatex } from '../../constants/constFunction';
import { bucket_url } from '../../constants/constString';
import { addQuestionForExam } from '../../stateManager/reducers/examSlice';
import {
	addQuestionForEdit,
	deleteQuestionRequest,
	updateQuestionRequest
} from '../../stateManager/reducers/questionSlice';
import MathInput from '../Common/MathJax';

const { Text } = Typography;

const QuestionTable = props => {
	const { data, examView, withExplanation, printAnswer } = props;
	console.log('QuestionTable', examView, withExplanation);
	const sortByDate = (a, b) => {
		const a1 = new Date(a.createdAt);
		const b1 = new Date(b.createdAt);
		console.log(a1, b1);
		return b1-a1;
	};
	const sortedData = data.slice().sort(sortByDate);
	let dataSource = [...sortedData];
	// dataSource.reverse();
	const dispatch = useDispatch();
	const history = useHistory();

	const handleQuestionUpdate = async (questionId, status) => {
		try {
			const res = await dispatch(
				updateQuestionRequest({
					data: { status: status },
					questionId: questionId
				})
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleQuestionDelete = async ({ questionId }) => {
		try {
			await dispatch(deleteQuestionRequest({ questionId }));
		} catch (error) {
			console.log(error);
		}
	};

	const getOptionType = (record) => {
		const optionType =
					record?.optionType && typeof record?.optionType === 'string'
						? JSON.parse(record?.optionType)
						: undefined;
		return optionType;
	}

	const columns = [
		{
			title: <strong className='no-print'>Question</strong>,
			key: 'question',
			render: (record, item, index) => {
				const optionType =
					record?.optionType && typeof record?.optionType === 'string'
						? JSON.parse(record?.optionType)
						: undefined;
				return (
						<Col className='questions-wrapper' xs={24} >
							<Card bordered={false} style={{ width: '100%' }}>
								<Row gutter={[10, 15]}>
									<Col xs={1} sm={1} style={{ textAlign: 'left' }}>
										<Text strong className='no-overflow-break'>
											{index + 1 + '.'}
										</Text>
									</Col>
									<Col
										xs={23}
										sm={23}
										style={{
											overflowX: 'auto'
										}}
									>
										<Text
											strong
											style={{
												fontSize: '18px',
												display: 'inline-block',
												width: '100%'
											}}
										>
											{item?.title ? (
												shouldUseKatex(item?.title) > -1 ? (
													<MathInput mathItem={item?.title} />
												) : (
													item?.title
												)
											) : (
												'Not given'
											)}
										</Text>
									</Col>
								</Row>
								{(item?.image || item?.file) &&
									(item?.image?.length > 0 || item?.file?.length > 0) && (
										<Row style={{ marginTop: 20, marginBottom: 20 }}>
											<Col xs={23} md={23} offset={1}>
												<Space>
													{!!item &&
														item.image.map(item => (
															<Image
																placeholder
																src={bucket_url + item}
																width={'100%'}
																style={{ objectFit: 'contain' }}
															/>
														))}
													{!!item &&
														item.file.map(item => (
															<a
																href={bucket_url + item}
																target='_blank'
																rel='noreferrer'
															>
																<Image
																	placeholder={<Image src={PdfPlaceholder} />}
																	src={bucket_url + item}
																	fallback={PdfPlaceholder}
																	width={'100%'}
																	style={{ objectFit: 'contain' }}
																/>
															</a>
														))}
												</Space>
											</Col>
										</Row>
									)}
								<Row>
									{(record?.type === 'MCQ') && (
										<Col xs={23} md={23} offset={1}>
											<Radio.Group
												style={{ width: '100%' }}
												value={item?.answer && item?.answer[0]}
											>
												<Row align='middle'>
													{item.options.map((option, index) => {
														return (
															<Col xs={24} md={24}>
																<Radio
																	className={`cardAnsGrid ${item.result ? 'result-input' : ''}`}
																	value={option}
																>
																	{optionType &&
																		optionType[option] &&
																		optionType[option] === 'image' ? (
																		<Image
																			placeholder
																			src={bucket_url + option}
																			width={'100%'}
																			style={{ objectFit: 'contain' }}
																		/>
																	) : (
																		<Text className='input-options'>
																			{shouldUseKatex(option) >= 0 ? (
																				<MathInput mathItem={option} />
																			) : (
																				option
																			)}
																		</Text>
																	)}
																</Radio>
															</Col>
														);
													})}
												</Row>
											</Radio.Group>
										</Col>
									)}
									{(record?.type === 'checkbox') && (
										<Col xs={23} md={23} offset={1}>
											<Checkbox.Group value={record?.answer} style={{ width: '100%' }}>
												<Row align='middle'>
													{record.options.map((option, index) => {
														return (
															<Col xs={24} md={24}>
																<Checkbox
																	key={index}
																	indeterminate={option.ans}
																	value={option}
																	className={`cardAnsGrid ${record.result ? 'result-input' : ''}`}
																	disabled={record.result}
																>
																	{/* <Text className='input-options'>
                                                {option && shouldUseKatex(option) > -1 ? (
                                                    <MathInput mathItem={option} />
                                                ) : (
                                                    option
                                                )}
                                            </Text> */}
																	{optionType &&
																		optionType[option] &&
																		optionType[option] === 'image' ? (
																		<Image
																			placeholder
																			src={bucket_url + option}
																			width={'100%'}
																			style={{ objectFit: 'contain' }}
																		/>
																	) : (
																		<Text className='input-options'>
																			{shouldUseKatex(option) >= 0 ? (
																				<MathInput mathItem={option} />
																			) : (
																				option
																			)}
																		</Text>
																	)}

																</Checkbox>
															</Col>
														);
													})}
												</Row>
											</Checkbox.Group>
										</Col>
									)}
									{/* Explanation Section Start */}
									{(record?.explanation || record?.explanationExt) && withExplanation && (
										<Col xs={24} md={24}>
											<Card className='explanation-card' bordered>
												<Text strong>Explanation:</Text>

												<p style={{ marginBottom: 0 }}>
													{record?.explanation &&
														(shouldUseKatex(record?.explanation) > -1 ? (
															<MathInput mathItem={record?.explanation} />
														) : (
															record?.explanation
														))}
												</p>
												<Row>
													<Col xs={24} md={24}>
														<Space>
															{record?.explanationExt &&
																record?.explanationExt.map(item => (
																	<a href={bucket_url + item} target='_blank' rel="noreferrer">
																		<Image
																			preview={false}
																			placeholder
																			src={bucket_url + item}
																			width={'100%'}
																			style={{ objectFit: 'contain' }}
																			fallback={PdfPlaceholder}
																		/>
																	</a>
																))}
														</Space>
													</Col>
												</Row>
											</Card>
										</Col>
									)}
								</Row>
								{
										!examView && (<Row marginTop style={{
											marginTop: '20px',
											paddingTop: '10px'
										
										}}>
											<Col>
											<Space>
											<Button
												onClick={() => {
													let obj = { ...item };
													delete obj['createdAt'];
													delete obj['updatedAt'];
													delete obj['createdBy'];
													if (obj.hasOwnProperty('__v')) {
														delete obj['__v'];
													}
													dispatch(
														addQuestionForEdit([
															{
																...obj
															}
														])
													);
													history.push('/edit-question');
												}}
												icon={<EditOutlined />}
											>
												Edit
											</Button>
											<Popconfirm
												title='Are you sure to delete this question?'
												onConfirm={() =>
													handleQuestionDelete({ questionId: item?._id })
												}
											>
												<Button danger icon={<DeleteOutlined />}>
													Delete
												</Button>
											</Popconfirm>
											{item?.status === 'pending' ? (
												<Button.Group>
													<Button
														type='primary'
														onClick={() =>
															handleQuestionUpdate(item._id, 'approved')
														}
													>
														Approve
													</Button>
													<Button
														onClick={() =>
															handleQuestionUpdate(item._id, 'rejected')
														}
														danger
													>
														Reject
													</Button>
												</Button.Group>
											) : (
												<Button
													color='green'
													type='primary'
													style={{
														background:
															item?.status === 'approved' ? 'green' : 'red'
													}}
												>
													{item?.status?.toUpperCase()}
												</Button>
											)}
										</Space></Col>
										
										</Row>)
									}
							</Card>
						</Col>
				);
			}
		}
	];
	console.log(columns,'test',dataSource)

	return (
		
		<>
		{
			!examView?
			<Table
			style={{ padding: 10 }}
			rowSelection={{
				type: 'checkbox',
				onChange: (key, row) => {
					dispatch(addQuestionForExam(row));
				},
				getCheckboxProps: record => {
					return {
						disabled: record.status !== 'approved'
					};
				}
			}}
			rowKey='_id'
			dataSource={dataSource}
			size='small'
			scroll={{ x: 100 }}
			columns={columns}
			pagination={false}
		/>:

		<>
		{
			!printAnswer &&
			<Row className='question-table-row'>
			{
				!printAnswer &&
				<div style={{position:'relative'}} >
					<div style={{display:'flex',justifyContent:'space-between',padding:'5px 10px' ,position:'relative'}}> 
							{
								localStorage.getItem('session') ?<div>Session {`${localStorage.getItem('session')} - ${Number(localStorage.getItem('session'))+1}`}</div>: ''
							}
						<div style={{margin: 0,position: 'absolute',  top: '50%',  left: '50%',  transform: 'translate(-50%, -50%)'}}>বিসমিল্লাহির রাহমানির রাহিম</div>
						
					</div>
					<div style={{border:'3px solid black', borderBottom:'0px'}}>
						<img src={'/question_header.png'}
							width={'100%'}
							height={'100%'}
							alt=''
						/>
						<div style={{display:'flex',position:'absolute',bottom:'0px', right:'20px', gap:'10px',}}>
							{localStorage.getItem('selectedLectureExamView') &&
							<div style={{color: 'black',background:'#B3B3B3',padding:'5px 15px',fontSize:'20px',height:'max-content'}}>{localStorage.getItem('selectedLectureExamView')}</div>
							}
							<div style={{color: 'black',background:'#B3B3B3',padding:'5px 15px',fontSize:'20px',height:'max-content'}}>পূর্ণমানঃ {dataSource.length}</div>
							<div style={{color: 'black',background:'#B3B3B3',padding:'5px 15px',fontSize:'20px',height:'max-content'}}>সময়ঃ {parseInt(dataSource.length* 0.5)} মিনিট</div>
						</div>
					</div>
				</div>
			}
			<Row style={{ border:examView && '3px solid black', borderTop: examView && '0px', minHeight:examView&&'100vh'}}>
			<Col span={12} className='questions-wrapper border-right'>
				{
					dataSource.slice(0,dataSource.length/2).map((item, index) => {
						return (
							<Row>
								<Col className={`questions-wrapper`} xs={24}>
									
									<Card className='pb-2' bordered={false} style={{ width: '100%',height:'100%' , }} bodyStyle={{padding: examView ?'0px 10px':''}}>
									
										<Row gutter={[10, 15]} align={'top'} justify={'center'}>
											{/* <Col xs={2} sm={1} style={{ textAlign: 'left' }}>
												<span strong className=''>
													
												</span>
											</Col> */}
											<Col
												xs={24}
												sm={24}
												style={{
													overflowX: 'auto',
													overflowY:'hidden'
												}}
											>
												{
													!examView && 
													<Checkbox onChange={
														(e) => {
															dispatch(addQuestionForExam(item));
														}
														}></Checkbox>
												}
												
												<Text
													strong
													style={{
														fontSize: '12px',
														display: 'inline-block',
														width: '100%'
													}}
											>	{index+1}{ `. `}
												{/* {index%2 == 0?parseInt(Math.floor(index/2)+1) + '. ': (parseInt(Math.ceil(index/2)))+ (Math.ceil(dataSource.length/2))	+ '. '} */}
													{item?.title ? (
														shouldUseKatex(item?.title) > -1 ? (
															<MathInput mathItem={item?.title} />
														) : (
															item?.title
														)
													) : (
														'Not given'
													)}
												</Text>
											</Col>
										</Row>
										{(item?.image || item?.file) &&
											(item?.image?.length > 0 || item?.file?.length > 0) && (
												<Row style={{ marginTop: 20, marginBottom: 20 }}>
													<Col xs={23} md={23} offset={1}>
														<Space>
															{!!item &&
																item.image.map(item => (
																	<Image
																		placeholder
																		src={bucket_url + item}
																		width={'100%'}
																		style={{ objectFit: 'contain' }}
																	/>
																))}
															{!!item &&
																item.file.map(item => (
																	<a
																		href={bucket_url + item}
																		target='_blank'
																		rel='noreferrer'
																	>
																		<Image
																			placeholder={<Image src={PdfPlaceholder} />}
																			src={bucket_url + item}
																			fallback={PdfPlaceholder}
																			width={'100%'}
																			style={{ objectFit: 'contain' }}
																		/>
																	</a>
																))}
														</Space>
													</Col>
												</Row>
											)}
											{
												examView?
												<Row>
												{(item?.type === 'MCQ') && (
													<Col xs={23} md={23} offset={1}>
														<Row align='middle'>
															{item.options.map((option, index) => {
																return (
																	<Col xs={12} md={12}>
																			{/* print a b c d BASED ON INDEX */}
																			{index === 0 ? <span strong>A. </span> : ''}
																			{index === 1 ? <span strong>B. </span> : ''}
																			{index === 2 ? <span strong>C. </span> : ''}
																			{index === 3 ? <span strong>D. </span> : ''}
																			{/* print a b c d BASED ON INDEX */}
																			{getOptionType(item) &&
																				getOptionType(item)[option] &&
																				getOptionType(item)[option][option] === 'image' ? (
																				<Image
																					placeholder
																					src={bucket_url + option}
																					width={'100%'}
																					style={{ objectFit: 'contain' }}
																				/>
																			) : (
																				<Text className='input-options'>
																					{shouldUseKatex(option) >= 0 ? (
																						<MathInput mathItem={option} />
																					) : (
																						option
																					)}
																				</Text>
																			)}
																	
																	</Col>
																);
															})}
														</Row>
													</Col>
												)}
												
												</Row>
												:
												<Row>
													{(item?.type === 'MCQ') && (
														
														<Col xs={23} md={23} offset={1}>
															<Radio.Group
																style={{ width: '100%' }}
																value={item?.answer && item?.answer[0]}
															>
																<Row align='middle'>
																	{item.options.map((option, index) => {
																		return (
																		
																				
																					<Col xs={24} md={24}>
																						<Radio
																							className={`cardAnsGrid ${item.result ? 'result-input' : ''}`}
																							value={option}
																						>
																							{getOptionType(item) &&
																								getOptionType(item)[option] &&
																								getOptionType(item)[option][option] === 'image' ? (
																								<Image
																									placeholder
																									src={bucket_url + option}
																									width={'100%'}
																									style={{ objectFit: 'contain' }}
																								/>
																							) : (
																								<Text className='input-options'>
																									{shouldUseKatex(option) >= 0 ? (
																										<MathInput mathItem={option} />
																									) : (
																										option
																									)}
																								</Text>
																							)}
																						</Radio>
																					</Col>
																					
																			
																		);
																	})}
																</Row>
															</Radio.Group>
														</Col>
													)}
													{(item?.type === 'checkbox') && (
														<Col xs={23} md={23} offset={1}>
															<Checkbox.Group value={item?.answer} style={{ width: '100%' }}>
																<Row align='middle'>
																	{item.options.map((option, index) => {
																		return (
																			<Col xs={24} md={24}>
																				<Checkbox
																					key={index}
																					indeterminate={option.ans}
																					value={option}
																					className={`cardAnsGrid ${item.result ? 'result-input' : ''}`}
																					disabled={item.result}
																				>
																					
																					{getOptionType(item) &&
																						getOptionType(item)[option] &&
																						getOptionType(item)[option] === 'image' ? (
																						<Image
																							placeholder
																							src={bucket_url + option}
																							width={'100%'}
																							style={{ objectFit: 'contain' }}
																						/>
																					) : (
																						<Text className='input-options'>
																							{shouldUseKatex(option) >= 0 ? (
																								<MathInput mathItem={option} />
																							) : (
																								option
																							)}
																						</Text>
																					)}

																				</Checkbox>
																			</Col>
																		);
																	})}
																</Row>
															</Checkbox.Group>
														</Col>
													)}
													
													{(item?.explanation || item?.explanationExt) && withExplanation && (
														<Col xs={24} md={24}>
															<Card className='explanation-card' bordered>
																<Text strong>Explanation:</Text>

																<p style={{ marginBottom: 0 }}>
																	{item?.explanation &&
																		(shouldUseKatex(item?.explanation) > -1 ? (
																			<MathInput mathItem={item?.explanation} />
																		) : (
																			item?.explanation
																		))}
																</p>
																<Row>
																	<Col xs={24} md={24}>
																		<Space>
																			{item?.explanationExt &&
																				item?.explanationExt.map(item => (
																					<a href={bucket_url + item} target='_blank' rel="noreferrer">
																						<Image
																							preview={false}
																							placeholder
																							src={bucket_url + item}
																							width={'100%'}
																							style={{ objectFit: 'contain' }}
																							fallback={PdfPlaceholder}
																						/>
																					</a>
																				))}
																		</Space>
																	</Col>
																</Row>
															</Card>
														</Col>
													)}
												</Row>
											}

											{
												!examView && (<Row marginTop style={{
													marginTop: '20px',
													paddingTop: '10px'
												
												}}>
													<Col>
													<Space>
													<Button
														onClick={() => {
															let obj = { ...item };
															delete obj['createdAt'];
															delete obj['updatedAt'];
															delete obj['createdBy'];
															if (obj.hasOwnProperty('__v')) {
																delete obj['__v'];
															}
															dispatch(
																addQuestionForEdit([
																	{
																		...obj
																	}
																])
															);
															history.push('/edit-question');
														}}
														icon={<EditOutlined />}
													>
														Edit
													</Button>
													<Popconfirm
														title='Are you sure to delete this question?'
														onConfirm={() =>
															handleQuestionDelete({ questionId: item?._id })
														}
													>
														<Button danger icon={<DeleteOutlined />}>
															Delete
														</Button>
													</Popconfirm>
													{item?.status === 'pending' ? (
														<Button.Group>
															<Button
																type='primary'
																onClick={() =>
																	handleQuestionUpdate(item._id, 'approved')
																}
															>
																Approve
															</Button>
															<Button
																onClick={() =>
																	handleQuestionUpdate(item._id, 'rejected')
																}
																danger
															>
																Reject
															</Button>
														</Button.Group>
													) : (
														<Button
															color='green'
															type='primary'
															style={{
																background:
																	item?.status === 'approved' ? 'green' : 'red'
															}}
														>
															{item?.status?.toUpperCase()}
														</Button>
													)}
												</Space></Col>
												
												</Row>)
											}
											
										
									</Card>
									
								</Col>
							</Row>
						);	
					})
						
				}
			</Col>
			<Col span={12} className='questions-wrapper'>
			{
				dataSource.slice(dataSource.length/2, dataSource.length).map((item, index) => {
					return (
						<Row> 
							<Col className={`questions-wrapper  `} xs={24}>
								
								<Card className='pb-2' bordered={false} style={{ width: '100%',height:'100%' , }} bodyStyle={{padding: examView ?'0px 10px':''}}>
								
									<Row gutter={[10, 15]} align={'top'} justify={'center'}>
										{/* <Col xs={2} sm={1} style={{ textAlign: 'left' }}>
											<span strong className=''>
												
											</span>
										</Col> */}
										<Col
											xs={24}
											sm={24}
											style={{
												overflowX: 'auto',
												overflowY:'hidden'
											}}
										>
											{
												!examView && 
												<Checkbox onChange={
													(e) => {
														dispatch(addQuestionForExam(item));
													}
													}></Checkbox>
											}
											
											<Text
												strong
												style={{
													fontSize: '12px',
													display: 'inline-block',
													width: '100%'
												}}
											>	{index+(dataSource.length/2)+1}{ `. ` }
											{/* {index%2 == 0?parseInt(Math.floor(index/2)+1) + '. ': (parseInt(Math.ceil(index/2)))+ (Math.ceil(dataSource.length/2))	+ '. '} */}
												{item?.title ? (
													shouldUseKatex(item?.title) > -1 ? (
														<MathInput mathItem={item?.title} />
													) : (
														item?.title
													)
												) : (
													'Not given'
												)}
											</Text>
										</Col>
									</Row>
									{(item?.image || item?.file) &&
										(item?.image?.length > 0 || item?.file?.length > 0) && (
											<Row style={{ marginTop: 20, marginBottom: 20 }}>
												<Col xs={23} md={23} offset={1}>
													<Space>
														{!!item &&
															item.image.map(item => (
																<Image
																	placeholder
																	src={bucket_url + item}
																	width={'100%'}
																	style={{ objectFit: 'contain' }}
																/>
															))}
														{!!item &&
															item.file.map(item => (
																<a
																	href={bucket_url + item}
																	target='_blank'
																	rel='noreferrer'
																>
																	<Image
																		placeholder={<Image src={PdfPlaceholder} />}
																		src={bucket_url + item}
																		fallback={PdfPlaceholder}
																		width={'100%'}
																		style={{ objectFit: 'contain' }}
																	/>
																</a>
															))}
													</Space>
												</Col>
											</Row>
										)}
										{
											examView?
											<Row>
											{(item?.type === 'MCQ') && (
												<Col xs={23} md={23} offset={1}>
													<Row align='middle'>
														{item.options.map((option, index) => {
															return (
																<Col xs={12} md={12}>
																		{/* print a b c d BASED ON INDEX */}
																		{index === 0 ? <span strong>A. </span> : ''}
																		{index === 1 ? <span strong>B. </span> : ''}
																		{index === 2 ? <span strong>C. </span> : ''}
																		{index === 3 ? <span strong>D. </span> : ''}
																		{/* print a b c d BASED ON INDEX */}
																		{getOptionType(item) &&
																			getOptionType(item)[option] &&
																			getOptionType(item)[option][option] === 'image' ? (
																			<Image
																				placeholder
																				src={bucket_url + option}
																				width={'100%'}
																				style={{ objectFit: 'contain' }}
																			/>
																		) : (
																			<Text className='input-options'>
																				{shouldUseKatex(option) >= 0 ? (
																					<MathInput mathItem={option} />
																				) : (
																					option
																				)}
																			</Text>
																		)}
																
																</Col>
															);
														})}
													</Row>
												</Col>
											)}
											
											</Row>
											:
											<Row>
												{(item?.type === 'MCQ') && (
													
													<Col xs={23} md={23} offset={1}>
														<Radio.Group
															style={{ width: '100%' }}
															value={item?.answer && item?.answer[0]}
														>
															<Row align='middle'>
																{item.options.map((option, index) => {
																	return (
																	
																			
																				<Col xs={24} md={24}>
																					<Radio
																						className={`cardAnsGrid ${item.result ? 'result-input' : ''}`}
																						value={option}
																					>
																						{getOptionType(item) &&
																							getOptionType(item)[option] &&
																							getOptionType(item)[option][option] === 'image' ? (
																							<Image
																								placeholder
																								src={bucket_url + option}
																								width={'100%'}
																								style={{ objectFit: 'contain' }}
																							/>
																						) : (
																							<Text className='input-options'>
																								{shouldUseKatex(option) >= 0 ? (
																									<MathInput mathItem={option} />
																								) : (
																									option
																								)}
																							</Text>
																						)}
																					</Radio>
																				</Col>
																				
																		
																	);
																})}
															</Row>
														</Radio.Group>
													</Col>
												)}
												{(item?.type === 'checkbox') && (
													<Col xs={23} md={23} offset={1}>
														<Checkbox.Group value={item?.answer} style={{ width: '100%' }}>
															<Row align='middle'>
																{item.options.map((option, index) => {
																	return (
																		<Col xs={24} md={24}>
																			<Checkbox
																				key={index}
																				indeterminate={option.ans}
																				value={option}
																				className={`cardAnsGrid ${item.result ? 'result-input' : ''}`}
																				disabled={item.result}
																			>
																				
																				{getOptionType(item) &&
																					getOptionType(item)[option] &&
																					getOptionType(item)[option] === 'image' ? (
																					<Image
																						placeholder
																						src={bucket_url + option}
																						width={'100%'}
																						style={{ objectFit: 'contain' }}
																					/>
																				) : (
																					<Text className='input-options'>
																						{shouldUseKatex(option) >= 0 ? (
																							<MathInput mathItem={option} />
																						) : (
																							option
																						)}
																					</Text>
																				)}

																			</Checkbox>
																		</Col>
																	);
																})}
															</Row>
														</Checkbox.Group>
													</Col>
												)}
												
												{(item?.explanation || item?.explanationExt) && withExplanation && (
													<Col xs={24} md={24}>
														<Card className='explanation-card' bordered>
															<Text strong>Explanation:</Text>

															<p style={{ marginBottom: 0 }}>
																{item?.explanation &&
																	(shouldUseKatex(item?.explanation) > -1 ? (
																		<MathInput mathItem={item?.explanation} />
																	) : (
																		item?.explanation
																	))}
															</p>
															<Row>
																<Col xs={24} md={24}>
																	<Space>
																		{item?.explanationExt &&
																			item?.explanationExt.map(item => (
																				<a href={bucket_url + item} target='_blank' rel="noreferrer">
																					<Image
																						preview={false}
																						placeholder
																						src={bucket_url + item}
																						width={'100%'}
																						style={{ objectFit: 'contain' }}
																						fallback={PdfPlaceholder}
																					/>
																				</a>
																			))}
																	</Space>
																</Col>
															</Row>
														</Card>
													</Col>
												)}
											</Row>
										}

										{
											!examView && (<Row marginTop style={{
												marginTop: '20px',
												paddingTop: '10px'
											
											}}>
												<Col>
												<Space>
												<Button
													onClick={() => {
														let obj = { ...item };
														delete obj['createdAt'];
														delete obj['updatedAt'];
														delete obj['createdBy'];
														if (obj.hasOwnProperty('__v')) {
															delete obj['__v'];
														}
														dispatch(
															addQuestionForEdit([
																{
																	...obj
																}
															])
														);
														history.push('/edit-question');
													}}
													icon={<EditOutlined />}
												>
													Edit
												</Button>
												<Popconfirm
													title='Are you sure to delete this question?'
													onConfirm={() =>
														handleQuestionDelete({ questionId: item?._id })
													}
												>
													<Button danger icon={<DeleteOutlined />}>
														Delete
													</Button>
												</Popconfirm>
												{item?.status === 'pending' ? (
													<Button.Group>
														<Button
															type='primary'
															onClick={() =>
																handleQuestionUpdate(item._id, 'approved')
															}
														>
															Approve
														</Button>
														<Button
															onClick={() =>
																handleQuestionUpdate(item._id, 'rejected')
															}
															danger
														>
															Reject
														</Button>
													</Button.Group>
												) : (
													<Button
														color='green'
														type='primary'
														style={{
															background:
																item?.status === 'approved' ? 'green' : 'red'
														}}
													>
														{item?.status?.toUpperCase()}
													</Button>
												)}
											</Space></Col>
											
											</Row>)
										}
										
									
								</Card>
								
							</Col>
						</Row>
					);	
				})
				
				
			}
			</Col>
			</Row>
			
		</Row>
		}
		
		{ printAnswer &&
			<div style={{border:'3px solid black',marginTop:'20px',width:'100%'}} className={`${printAnswer?'print-teacher':''}`}>
				<div style={{textAlign:'center',display:'block',padding:'0px 10px' , color:'#000',fontSize:'20px'}}>
					<p style={{position:'relative',width:'100%',marginBottom:'0'}}>RETINA <span style={{position:'absolute',right:'0',fontSize:'14px'}}></span></p>
					<p style={{marginBottom:'0'}}>Answer Sheet: {localStorage.getItem('selectedLectureExamView') } ({localStorage.getItem('session')})</p>
				</div>
				<table className='answer-table' style={{width:'100%'}}>
					<thead>
						<tr>
							<th>Q No.</th>
							<th>Ans</th>
							<th>Explanation</th>
						</tr>
					</thead>
					<tbody>
						{
							dataSource.map((item, index) => {
								return (
									<tr>
										<td>{index+1}</td>
										<td>{item?.answer && item?.options?.map(
											(option, index) => {
												if(item?.answer[0] === option){
													return (()=>{
														if(index === 0) return 'A'
														if(index === 1) return 'B'
														if(index === 2) return 'C'
														if(index === 3) return 'D'
													})()
												// 	<Text className='input-options'>
												// 	{shouldUseKatex(option) >= 0 ? (
												// 		<MathInput mathItem={option} />
												// 	) : (
												// 		option
												// 	)}
												// </Text>
												}
											}
										
										)}</td>
										<td style={{textAlign:'left'}}>{item?.explanation &&
															(shouldUseKatex(item?.explanation) > -1 ? (
																<MathInput mathItem={item?.explanation} />
															) : (
																item?.explanation
															))}</td>
									</tr>
								)
							}
							)
						}
						
					</tbody>
				</table>
			</div>
		}
		</>
		
		}
		
		</>
		
		
	);
};

export default QuestionTable;
